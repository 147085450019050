<template>
  <!-- 12-2 -->
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p class="breadcrumb-menu">
        <router-link :to="{ name: 'Tournaments', query: this.createSearchQuery() }" class="ashen-link">大会管理
        </router-link>
        <i class="fas fa-angle-right form-control-color"></i> 大会新規登録
      </p>
    </div>
    <div class="card p-5 text-first">
      <div class="card-body mx-auto">
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">大会名</label>
            <input
                v-model="tournament.tournamentName"
                class="form-control"
                placeholder=""
                type="text"
            />
            <div v-show="errors['tournamentName']" class="form-error">
              {{ errors["tournamentName"] }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="startDate">大会開始日</label>
            <div class="input-group">
              <!--              <input-->
              <!--                  v-model="tournament.startAt"-->
              <!--                  class="form-control mt-2 full-width-input"-->
              <!--                  type="text"-->
              <!--              />-->
              <!--              <div class="input-group-append mt-2">-->
              <!--                <input-->
              <!--                    id="startDate"-->
              <!--                    class="form-control datepicker"-->
              <!--                    type="date"-->
              <!--                    :value="startAtForDatePicker"-->
              <!--                    @change="changeStartDate"-->
              <!--                />-->
              <!--              </div>-->
              <Calender v-bind:currentDate="tournament.startAt"
                        @update="updateStartAt"
              ></Calender>
            </div>
            <div v-show="errors['startAt']" class="form-error">
              {{ errors["startAt"] }}
            </div>
          </div>
          <div class="col">
            <label for="endDate">大会終了日</label>
            <div class="input-group">
              <!--              <input-->
              <!--                  v-model="tournament.endAt"-->
              <!--                  class="form-control mt-2 full-width-input"-->
              <!--                  type="text"-->
              <!--              />-->
              <!--              <div class="input-group-append mt-2">-->
              <!--                <input-->
              <!--                    id="endDate"-->
              <!--                    class="form-control datepicker"-->
              <!--                    type="date"-->
              <!--                    :value="endAtForDatePicker"-->
              <!--                    @change="changeEndDate"-->
              <!--                />-->
              <!--              </div>-->
              <Calender v-bind:currentDate="tournament.endAt"
                        @update="updateEndAt"
              ></Calender>
            </div>
            <div v-show="errors['endAt']" class="form-error">
              {{ errors["endAt"] }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label>開催都道府県</label>
            <select
                v-model="tournament.hostPrefectureId"
                class="form-control filter-select-long form-select mt-1"
            >
              <option
                  v-for="(prefecture, prefectureId) in prefectures"
                  :key="prefectureId"
                  :value="prefecture.prefectureId"
              >
                {{ prefecture.prefectureName }}
              </option>
            </select>
            <div v-show="errors['hostPrefectureId']" class="form-error">
              {{ errors["hostPrefectureId"] }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">主催団体</label>
            <input
                v-model="tournament.hostOrganization"
                class="form-control"
                placeholder=""
                type="email"
            />
            <div v-show="errors['hostOrganization']" class="form-error">
              {{ errors["hostOrganization"] }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">お問い合わせ電話番号</label>
            <input
                v-model="tournament.inquiryPhoneNo"
                class="form-control"
                placeholder=""
                type="email"
            />
            <div v-show="errors['inquiryPhoneNo']" class="form-error">
              {{ errors["inquiryPhoneNo"] }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">お問い合わせメール</label>
            <input
                v-model="tournament.inquiryEmail"
                class="form-control"
                placeholder=""
                type="email"
            />
            <div v-show="errors['inquiryEmail']" class="form-error">
              {{ errors["inquiryEmail"] }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1"
            >大会管理者<span class="color-88"
            >（複数名の場合はセミコロン「;」で区切って入力してください）</span
            ></label
            >
            <input
                v-model="tournament.tournamentAdminEmail"
                class="form-control"
                placeholder=""
                type="email"
            />
            <div v-show="errors['tournamentAdminEmail']" class="form-error">
              {{ errors["tournamentAdminEmail"] }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1"
            >請求書先</label
            >
            <input
                v-model="tournament.billingDestination"
                class="form-control"
                placeholder=""
                type="text"
            />
            <div v-show="errors['billingDestination']" class="form-error">{{ errors['billingDestination'] }}</div>
          </div>
        </div>
        <div class="form-check ml-2 mt-4 mx-auto">
          <input
              id="flexCheckDefault2"
              v-model="tournament.pastAnswersRequired"
              class="form-check-input m-t-5"
              type="checkbox"
              value=""
          />
          <label class="form-check-label" for="flexCheckDefault2"
          >過去14日間における回答をさせる
          </label>
          <br>
            <input id="flexCheckDefault"
                   class="form-check-input m-t-10"
                   type="checkbox"
                   v-model="tournament.hasHeatStroke"
                   value="">
            <label class="form-check-label m-t-5" for="flexCheckDefault">熱中症の項目を表示させる
            </label>

        </div>
        <div class="row mt-4">
          <div class="col-6">
            <button
                class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100"
                @click="backToTournament"
            >
              キャンセル
            </button>
          </div>
          <div class="col-6 text-end">
            <button
                class="btn col btn-primary btn-outline-light font-weight-bold w-100"
                @click="createTournament"
            >
              登録
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {app, apiConfig} from "@/constants"
import {mapGetters} from "vuex"
import Common from "jsadminclient/common"
import Calender from "../components/layouts/Calendar";

export default {
  name: "TournamentRegistration",
  components: {Calender},
  props: {
    defaultResponse: {type: Object, default: null},
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      loading: true,
      tournament: {},
      prefectures: {},
      errors: [],
    };
  },
  mounted() {
    this.apiConfig.headers["X-API-Token"] = this.config.token;
    this.initialPageLoadHandler()
  },
  computed: {
    ...mapGetters(["config"]),
    startAtForDatePicker() {
      return Common.dateFormatConvertForDatePicker(this.tournament.startAt)
    },
    endAtForDatePicker() {
      return Common.dateFormatConvertForDatePicker(this.tournament.endAt)
    },
  },
  methods: {
    initialPageLoadHandler() {
      this.addTournament()
      $(".tooltip").hide();
    },
    addTournament() {
      this.tournament = {
        tournamentName: "",
        startAt: this.getDateFormat(new Date()),
        endAt: this.getDateFormat(new Date(), 1),
        hostPrefectureId: 13,
        hostOrganization: "",
        inquiryEmail: "",
        tournamentAdminEmail: "",
        billingDestination: "",
        pastAnswersRequired: false,
        hasHeatStroke: true,
        inquiryPhoneNo: "",
      };
      this.getPrefecture();
    },
    backToTournament() {
      this.$router.push({
        name: "Tournaments",
        params: {defaultResponse: this.defaultResponse},
        query: this.createSearchQuery()
      });
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    async createTournament() {
      try {
        this.errors = []
        const api = new AdminApi.JTATournamentApi(this.getApiConfig())
        var result = await api.jtaTournamentsPostAsync(this.tournament)
        Common.showToast(`大会が登録されました。`)
        this.backToTournament()
        this.getData()
      } catch (e) {
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: "Login"})
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getPrefecture() {
      try {
        const api = new AdminApi.SystemApi(this.getApiConfig());
        var result = await api.systemPrefecturesGetAsync();
        this.prefectures = result.items;
      } catch (e) {
        this.prefectures = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response);
        if (isTokenError) {
          this.$router.push({name: "Login"});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    getDateFormat(dateObject, addMonths = 0) {
      return moment(dateObject).add(addMonths, "months").format("YYYY/MM/DD")
    },
    changeStartDate(e) {
      this.tournament.startAt = this.getDateFormat(e.target.value)
    },
    changeEndDate(e) {
      this.tournament.endAt = this.getDateFormat(e.target.value)
    },
    updateStartAt(date) {
      this.tournament.startAt = date
    },
    updateEndAt(date) {
      this.tournament.endAt = date
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
};
</script>

<style lang="scss" scoped>

.form-control {
  &:not(select) {
    //background: #F2ECF0;
    border: none;
  }

  &.datepicker {
    &::-webkit-calendar-picker-indicator {
      background: url("../assets/images/calendar-event.svg") no-repeat;
    }
  }
}

::placeholder {
  color: #333333;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;
}

.ok {
  width: 414px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok:active {
  color: white;
  background-color: #c95d6c;
}

.cancel {
  width: 414px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel:active {
  color: #c95d6c;
  background-color: #ffffff;
}

.delete {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #333333;
}

.delete:active {
  color: white;
  background-color: #333333;
}

.ok-min {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok-min:active {
  color: white;
  background-color: #c95d6c;
}

.cancel-min {
  width: 200px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel-min:active {
  color: #333333;
  background-color: #ffffff;
}

a {
  color: #333333;
}

.filter-select {
  //background-image: url('./src/assets/images/down.png');
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-search-form {
  border: 1px solid #dee2e6 !important;
  height: 32px !important;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.content {
  &.tournaments {
    .text-888888 {
      color: #888888;
    }

    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }
}

</style>
